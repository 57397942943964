import styled from "styled-components";

import { ReactComponent as PhoneSvg } from "../../assets/svg/phone.svg";
import { ReactComponent as ClockSvg } from "../../assets/svg/clock.svg";
import { ReactComponent as EmailSvg } from "../../assets/svg/mail.svg";
import { ReactComponent as LogoSvg } from "../../assets/svg/cup.svg";
import { ReactComponent as MapSvg } from "../../assets/svg/location.svg";

export const ContactContainer = styled.div`
  padding: 10px 5.4vw 96px;

  @media screen and (max-width: 750px) {
    padding: 14px 5.4vw 42px;
  }
  display: flex;
  justify-content: space-between;
  gap: 20px;

  @media screen and (max-width: 1250px) {
    flex-direction: column;
    gap: 32px;
    align-items: center;
    justify-content: center;
  }
`;

export const ContactFormContainer = styled.div`
  width: 51.5vw;

  @media screen and (max-width: 1250px) {
    width: 100%;
  }
`;

export const ContactHeader = styled.div`
  font-size: 55.9px;
  font-weight: 500;
  line-height: 60px;
  text-align: left;
  color: rgba(47, 174, 27, 1);
  margin-bottom: 20px;

  @media screen and (max-width: 750px) {
    font-size: 36px;
    font-weight: 600;
    margin-bottom: 16px;
  }
`;

export const ContactDescription = styled.div`
  font-size: 18.59px;
  font-weight: 400;
  text-align: left;
  margin-bottom: 28px;

  @media screen and (max-width: 750px) {
    font-size: 14px;
    margin-bottom: 20px;
  }
`;

export const InputName = styled.input`
  width: 56.3%;
  height: 58px;
  box-sizing: border-box;
  padding: 17px 21px;
  border-radius: 16px;
  background: white;
  border: 2px solid rgba(174, 174, 174, 1);
  font-family: Inter;
  font-size: 16.17px;
  font-weight: 400;
  line-height: 19.57px;
  text-align: left;

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: rgba(174, 174, 174, 1);
  }

  @media screen and (max-width: 750px) {
    width: 100%;
  }
`;

export const InputPhone = styled(InputName)`
  width: 41.7%;
  height: 58px;

  @media screen and (max-width: 750px) {
    width: 100%;
  }
`;

export const InputesContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 750px) {
    flex-direction: column;
    gap: 12px;
  }
`;

export const QuestionArea = styled.textarea`
  margin-top: 23px;
  margin-bottom: 27px;
  width: 100%;
  background: white;
  color: black;
  width: 100%;
  box-sizing: border-box;
  height: 162px;
  padding: 19px 21px;
  border-radius: 16px;
  border: 2px solid rgba(174, 174, 174, 1);
  font-family: Inter;
  font-size: 16.17px;
  font-weight: 400;
  line-height: 19.57px;
  text-align: left;

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: rgba(174, 174, 174, 1);
  }

  @media screen and (max-width: 750px) {
    margin-top: 12px;
    margin-bottom: 16px;
    height: 290px;
  }
`;

export const SendBtn = styled.div`
  font-size: 18.44px;
  font-weight: 500;
  line-height: 22.31px;
  width: 100%;
  text-align: center;
  color: white;
  box-sizing: border-box;
  padding: 20px 0;
  border-radius: 16px;
  background: rgba(47, 174, 27, 1);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
`;

export const ContactInfoPart = styled.div`
  width: 36.5vw;
  height: 332px;
  box-sizing: border-box;
  border-radius: 16px;
  border: 2px solid rgba(174, 174, 174, 1);
  padding: 29px 32px;
  display: flex;
  justify-content: space-between;
  align-self: flex-end;

  @media screen and (max-width: 1250px) {
    flex-direction: column;
    gap: 20px;
    align-self: center;
    height: auto;
    width: 430px;
  }

  @media screen and (max-width: 750px) {
    border: none;
    width: 100%;
    padding: 0;
  }
`;

export const ContactInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  align-items: flex-start;
  justify-content: center;

  @media screen and (max-width: 750px) {
    width: 100%;
    justify-content: flex-start;
  }
`;

export const InfoContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;
`;

export const InfoTextContainer = styled.div`
  font-size: 18.75px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: rgba(24, 24, 24, 1);
  display: flex;
  gap: 10px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  max-width: 260px;
`;

export const PhoneIcon = styled(PhoneSvg)`
  box-sizing: border-box;
  width: 64px;
  min-width: 64px;
  height: 64px;
  min-height: 64px;
  padding: 19px;
  border-radius: 20px;
  background: rgba(47, 174, 27, 1);
`;

export const EmailIcon = styled(EmailSvg)`
  box-sizing: border-box;
  width: 64px;
  min-width: 64px;
  height: 64px;
  min-height: 64px;
  padding: 19px;
  border-radius: 20px;
  background: rgba(47, 174, 27, 1);
`;

export const ClockIcon = styled(ClockSvg)`
  box-sizing: border-box;
  width: 64px;
  min-width: 64px;
  height: 64px;
  min-height: 64px;
  padding: 19px;
  border-radius: 20px;
  background: rgba(47, 174, 27, 1);
`;

export const MapIcon = styled(MapSvg)`
  box-sizing: border-box;
  width: 64px;
  min-width: 64px;
  height: 64px;
  min-height: 64px;
  padding: 19px;
  border-radius: 20px;
  background: rgba(47, 174, 27, 1);
`;

export const ContactLogo = styled(LogoSvg)`
  box-sizing: border-box;
  width: 64px;
  min-width: 64px;
  height: 64px;
  min-height: 64px;
  width: 258px;
  height: 172px;
  align-self: center;
`;
