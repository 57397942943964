import styled from "styled-components";
import { Map } from "@pbe/react-yandex-maps";

import {
  ContactHeader,
  InfoContainer,
  SendBtn,
} from "../contact/contact.styles";

export const MapContainer = styled.div`
  padding: 0 5.4vw 78px;
  display: flex;
  justify-content: space-between;
  gap: 5.4vw;

  @media screen and (max-width: 750px) {
    padding: 0 5.4vw 42px;
  }

  @media screen and (max-width: 1250px) {
    flex-direction: column;
    gap: 30px;
    align-items: flex-start;
    justify-content: center;
  }
`;

export const MapInfoContainer = styled.div`
  width: 577px;
  height: 640px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  @media screen and (max-width: 1250px) {
    width: 100%;
    height: auto;
  }
`;

export const StyledMap = styled(Map)`
  width: 100%;
  height: 100%;
`;

export const YMapContainer = styled.div`
  min-width: 59vw;
  width: 59vw;
  height: 640px;
  border-radius: 20px;
  overflow: hidden;

  @media screen and (max-width: 1250px) {
    width: 100%;
  }

  @media screen and (max-width: 750px) {
    height: 393px;
  }
`;

export const MapHeader = styled(ContactHeader)`
  margin-bottom: 85px;

  @media screen and (max-width: 1250px) {
    margin-bottom: 30px;
  }
`;

export const AddressContainer = styled(InfoContainer)`
  margin-bottom: 64px;

  @media screen and (max-width: 1250px) {
    margin-bottom: 70px;
  }
`;

export const WorkContainer = styled(InfoContainer)`
  margin-bottom: 178px;

  @media screen and (max-width: 1250px) {
    margin-bottom: 0;
  }
`;

export const ContactBtn = styled(SendBtn)`
  @media screen and (max-width: 1250px) {
    display: none;
  }
`;

export const MobileContactBtn = styled(SendBtn)`
  @media screen and (min-width: 1250px) {
    display: none;
  }
`;
