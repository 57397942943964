import React, { FC, useContext } from "react";

import { LngContext } from "../../context/lng-context";

import bg from "../../assets/img/tea-field.jpg";
import leaf from "../../assets/img/leaf.png";

import {
  ContactButton,
  HeaderContainer,
  HeaderContent,
  HeaderContentContainer,
  HeaderImage,
  HeaderText,
  HeaderTextGreen,
  Leaf,
  LeafContainer,
  LeafMirror,
  LinkIcon,
} from "./header.styles";
const Header: FC<{ navToContact: () => void }> = ({ navToContact }) => {
  const texts = useContext(LngContext);

  return (
    <HeaderContainer>
      <HeaderContentContainer>
        <HeaderText>
          {texts.header.title}
          <HeaderTextGreen>{texts.header.titleGreen}</HeaderTextGreen>
        </HeaderText>
        <HeaderContent>{texts.header.text}</HeaderContent>
        <ContactButton onClick={navToContact}>
          {texts.header.contact} <LinkIcon />
        </ContactButton>
      </HeaderContentContainer>
      <HeaderImage src={bg} />
      <Leaf src={leaf} />
      <LeafMirror src={leaf} />
    </HeaderContainer>
  );
};

export default Header;
