import React from "react";

import { GlobalStyle } from "./global.styles";
import AppLoyout from "./routes/apployout";

function App() {
  return (
    <div>
      <GlobalStyle />
      <AppLoyout />
    </div>
  );
}

export default App;
