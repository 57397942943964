import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`

body {
  margin: 0;
  background: white;
  font-family: Inter;
  overflow-x: hidden;

  .hidden {
    display: none;
  }
}
`;
