import React, { useContext, FC } from "react";

import { LngContext } from "../../context/lng-context";

import {
  FotterContainer,
  FotterLastPartContainer,
  FotterLinksContainer,
  FotterNumberContainer,
  GreenLogo,
  NavContainer,
} from "./footer.styles";
import { InstaIcon, FBIcon } from "../top-menu/top-menu.styles";

const Fotter: FC<{
  navigation: {
    navToAbout: () => void | undefined;
    navToCatalog: () => void | undefined;
    navToContacts: () => void | undefined;
  };
}> = ({ navigation }) => {
  const texts = useContext(LngContext);

  const openInsta = () => {
    window.open(
      "https://www.instagram.com/amirtea_samarkand/profilecard/?igsh=Y2poc2xlcjUwOTNk",
      "_blank",
      "noopener,noreferrer"
    );
  };

  const openFB = () => {
    window.open(
      "https://www.facebook.com/share/19eaHTKstQ/?mibextid=LQQJ4d",
      "_blank",
      "noopener,noreferrer"
    );
  };

  return (
    <FotterContainer>
      <GreenLogo />
      <NavContainer>
        <span onClick={() => navigation.navToAbout()}>
          {texts.topMenu.about}
        </span>
        <span onClick={() => navigation.navToCatalog()}>
          {texts.topMenu.catalog}
        </span>
        <span onClick={() => navigation.navToContacts()}>
          {texts.topMenu.contact}
        </span>
      </NavContainer>
      <FotterLastPartContainer>
        <FotterLinksContainer>
          <InstaIcon onClick={openInsta} />
          <FBIcon onClick={openFB} />
        </FotterLinksContainer>
        <FotterNumberContainer>+998 55 702 70 02</FotterNumberContainer>
      </FotterLastPartContainer>
    </FotterContainer>
  );
};

export default Fotter;
