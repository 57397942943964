import styled from "styled-components";

import { ReactComponent as LovoSvg } from "../../assets/svg/logo.svg";
import { ReactComponent as BoxSvg } from "../../assets/svg/box.svg";
import { ReactComponent as LikeSvg } from "../../assets/svg/like.svg";
import { ReactComponent as WorkerSvg } from "../../assets/svg/worker.svg";
import { ReactComponent as MarkSvg } from "../../assets/svg/mark.svg";

export const AboutContainer = styled.div`
  width: 100%;
`;

export const AboutFirst = styled.div`
  width: 100%;
  height: 722px;
  /* margin-top: 20px; */
  box-sizing: border-box;
  padding: 62px 5.4vw 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  position: relative;

  overflow-x: clip;

  @media screen and (max-width: 1550px) {
    grid-template-columns: 100%;
    /* grid-template-rows: 2fr 1fr; */
    grid-template-rows: auto auto;
    gap: 35vw;
    height: 70vw;
    min-height: 1060px;
  }

  @media screen and (max-width: 750px) {
    height: auto;
    min-height: auto;
  }
`;

export const AboutFirstImg = styled.img`
  /* width: 1638px; */
  /* height: 840px; */
  width: 844px;
  height: 615px;
  position: absolute;
  /* bottom: -277px; */
  /* left: -330px; */
  bottom: -30px;
  left: -20px;

  @media screen and (max-width: 1700px) {
    left: -15px;
    width: 675px;
    height: 492px;
  }

  @media screen and (max-width: 1550px) {
    grid-row-start: 2;
    /* width: 100.5vw; */
    /* height: 45.4vw; */
    width: 100vw;
    height: 72.5vw;
    max-width: 844px;
    max-height: 615px;
    /* min-width: 791px;
    min-height: 360px; */
    left: 47.5%;
    transform: translate(-50%, 0);
    /* bottom: -14vw; */
    bottom: -320px;
  }

  @media screen and (max-width: 850px) {
    bottom: -37.6vw;
  }

  /* @media screen and (max-width: 750px) {
    bottom: -105px;
  } */
`;

export const AboutFirstTextCntainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  grid-column-start: 2;

  @media screen and (max-width: 1550px) {
    grid-column-start: 1;
    grid-column-end: 1;
    grid-row-start: 1;
    grid-row-end: 1;
  }
`;

export const AboutFirstTitle = styled.div`
  font-size: 54px;
  font-weight: 600;
  line-height: 64.8px;
  text-align: left;
  color: rgba(18, 18, 18, 1);

  @media screen and (max-width: 750px) {
    font-size: 36px;
    line-height: 43.2px;
  }
`;

export const MotoContiner = styled.div`
  width: 778px;
  height: 60px;
  box-sizing: border-box;
  padding: 12px 32px;
  border-radius: 12px;
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
  text-align: center;
  color: white;
  background: rgba(47, 174, 27, 1);
  margin-top: 12px;
  margin-bottom: 32px;

  @media screen and (max-width: 1550px) {
    width: 100%;
    height: auto;
  }

  @media screen and (max-width: 750px) {
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
  }
`;

export const AboutPharagraph = styled.div`
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  text-align: left;
  color: rgba(18, 18, 18, 1);
  margin-bottom: 30px;

  @media screen and (max-width: 750px) {
    font-size: 16px;
    font-weight: 400;
    line-height: 20.8px;
    margin-bottom: 20.8px;
    width: 100%;
  }
`;

export const AboutSecond = styled.div`
  width: 100%;
  height: 749px;
  box-sizing: border-box;
  padding: 151px 5.4vw 0;
  background: linear-gradient(180deg, #3a7a30 0%, #2fae1b 100%);
  display: grid;
  grid-template-columns: 1fr 1fr;

  @media screen and (max-width: 1550px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 24px;
    /* height: 70vw;
    min-height: 960px; */
    height: auto;
  }

  @media screen and (max-width: 750px) {
    padding-top: 74px;
  }
`;

export const AboutSecondTextCntainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const SecondTitle = styled.div`
  font-size: 54px;
  font-weight: 600;
  line-height: 60px;
  text-align: left;
  color: white;
  max-width: 580px;
  margin-bottom: 36px;

  @media screen and (max-width: 1550px) {
    max-width: 100%;
  }

  @media screen and (max-width: 750px) {
    font-size: 32px;
    line-height: 36.29px;
    margin-bottom: 16px;
  }
`;

export const SecondText = styled.div`
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
  color: white;
  max-width: 690px;

  @media screen and (max-width: 1550px) {
    max-width: 100%;
  }

  @media screen and (max-width: 750px) {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.01em;
  }
`;

export const AboutLogoBig = styled(LovoSvg)`
  margin-top: 138px;
  width: 180px;
  height: 46px;

  @media screen and (max-width: 1550px) {
    display: none;
  }
`;

export const AboutLogoSmall = styled(LovoSvg)`
  margin-top: 50px;
  margin-bottom: 48px;
  width: 240px;
  height: 62px;

  @media screen and (min-width: 1550px) {
    display: none;
  }
`;

export const AboutBoxesContainer = styled.div`
  display: grid;
  gap: 24px;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 238px 238px;

  @media screen and (max-width: 1550px) {
    display: flex;
    flex-direction: column;
  }
`;

export const AboutBox = styled.div`
  width: 100%;
  height: 100%;
  padding: 20px;
  border-radius: 23px;
  box-sizing: border-box;
  background: white;
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: flex-start;
  justify-content: flex-start;

  @media screen and (max-width: 1550px) {
    height: 230px;
  }
`;

export const AboutBoxTitle = styled.div`
  height: 68px;
  min-height: 68px;
  background: rgba(47, 174, 27, 1);
  box-sizing: border-box;
  border-radius: 16px;
  padding: 0 19px;

  font-size: 32.54px;
  font-weight: 500;
  line-height: 35px;
  white-space: nowrap;
  text-align: left;

  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
  color: white;

  @media screen and (max-width: 1550px) {
    padding: 0 13px;
    gap: 22px;
    font-size: 24px;
  }
`;

export const AboutBoxText = styled.div`
  font-size: 18px;
  font-weight: 500;
  line-height: 21.6px;
  text-align: left;
`;

export const BoxIcon = styled(BoxSvg)`
  width: 42.8px;
  height: 45.07px;
`;

export const LikeIcon = styled(LikeSvg)`
  width: 45px;
  height: 45px;
`;

export const WorkerIcon = styled(WorkerSvg)`
  width: 32px;
  height: 44px;
`;

export const MarkIcon = styled(MarkSvg)`
  width: 44.64px;
  height: 44.16px;
`;
