import React, { useContext, FC } from "react";
import { Placemark, YMaps } from "@pbe/react-yandex-maps";

import { LngContext } from "../../context/lng-context";
import {
  AddressContainer,
  ContactBtn,
  MapContainer,
  MapHeader,
  MapInfoContainer,
  MobileContactBtn,
  StyledMap,
  WorkContainer,
  YMapContainer,
} from "./map.styles";
import {
  ClockIcon,
  InfoTextContainer,
  MapIcon,
} from "../contact/contact.styles";
import { LinkIcon } from "../header/header.styles";

const MapComponent: FC<{ navToContact: () => void }> = ({ navToContact }) => {
  const texts = useContext(LngContext);

  return (
    <MapContainer>
      <MapInfoContainer>
        <MapHeader>{texts.map.title}</MapHeader>
        <AddressContainer>
          <MapIcon />
          <InfoTextContainer>
            <span>{texts.map.addressTitle}</span>
            <span>{texts.map.address}</span>
          </InfoTextContainer>
        </AddressContainer>
        <WorkContainer>
          <ClockIcon />
          <InfoTextContainer>
            <span>{texts.map.work}</span>
            <span>{texts.map.time}</span>
          </InfoTextContainer>
        </WorkContainer>
        <ContactBtn onClick={navToContact}>
          {texts.map.contact} <LinkIcon />
        </ContactBtn>
      </MapInfoContainer>
      <YMapContainer>
        <YMaps>
          <StyledMap
            defaultState={{
              center: [39.650516, 67.047114],
              zoom: 14,
              controls: ["zoomControl"],
            }}
            modules={["control.ZoomControl"]}>
            <Placemark defaultGeometry={[39.650516, 67.047114]} />
          </StyledMap>
        </YMaps>
      </YMapContainer>
      <MobileContactBtn onClick={navToContact}>
        {texts.map.contact} <LinkIcon />
      </MobileContactBtn>
    </MapContainer>
  );
};

export default MapComponent;
