import styled from "styled-components";

import { ReactComponent as LogoSvg } from "../../assets/svg/logo-green.svg";

export const FotterContainer = styled.div`
  width: 100%;
  height: 163px;
  background: black;
  box-sizing: border-box;
  padding: 0 5.4vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;

  @media screen and (max-width: 1080px) {
    flex-direction: column;
    height: auto;
    align-items: center;
    justify-content: space-between;
    padding: 49px 5.4vw 79px;
    gap: 44px;
  }
`;

export const GreenLogo = styled(LogoSvg)`
  width: 275px;
  height: 70.28px;
`;

export const NavContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3.1vw;
  font-size: 18.91px;
  font-weight: 400;
  line-height: 22.88px;
  text-align: center;
  color: white;
  white-space: nowrap;

  @media screen and (max-width: 1080px) {
    flex-direction: column;
    height: auto;
    align-items: center;
    justify-content: space-between;
    gap: 24px;
    padding-top: 20px;
  }
`;

export const FotterNumberContainer = styled.div`
  width: 269px;
  height: 44px;
  box-sizing: border-box;
  padding: 18px 34px;
  border-radius: 9px;
  font-size: 19.06px;
  font-weight: 500;
  text-align: center;
  color: white;
  background: linear-gradient(90deg, #3a7a30 0%, #2fae1b 100%);
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const FotterLastPartContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 9.3vw;

  @media screen and (max-width: 1400px) {
    gap: 50px;
  }

  @media screen and (max-width: 1080px) {
    flex-direction: column;
    height: auto;
    align-items: center;
    justify-content: space-between;
    gap: 47px;
  }
`;

export const FotterLinksContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1.45vw;
`;
