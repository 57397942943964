import React, { useContext } from "react";

import { LngContext } from "../../context/lng-context";

import teaImg from "../../assets/img/tea2.png";
import {
  AboutBox,
  AboutBoxesContainer,
  AboutBoxText,
  AboutBoxTitle,
  AboutContainer,
  AboutFirst,
  AboutFirstImg,
  AboutFirstTextCntainer,
  AboutFirstTitle,
  AboutLogoBig,
  AboutLogoSmall,
  AboutPharagraph,
  AboutSecond,
  AboutSecondTextCntainer,
  BoxIcon,
  LikeIcon,
  MarkIcon,
  MotoContiner,
  SecondText,
  SecondTitle,
  WorkerIcon,
} from "./about.styles";

const About = () => {
  const texts = useContext(LngContext);

  return (
    <AboutContainer>
      <AboutFirst>
        <AboutFirstImg src={teaImg} />
        <AboutFirstTextCntainer>
          <AboutFirstTitle>{texts.about.title}</AboutFirstTitle>
          <MotoContiner>{texts.about.motto}</MotoContiner>
          <AboutPharagraph>{texts.about.text1}</AboutPharagraph>
          <AboutPharagraph>{texts.about.text2}</AboutPharagraph>
          <AboutPharagraph>{texts.about.text3}</AboutPharagraph>
        </AboutFirstTextCntainer>
      </AboutFirst>
      <AboutSecond>
        <AboutSecondTextCntainer>
          <SecondTitle>{texts.about.header2}</SecondTitle>
          <SecondText>{texts.about.secondPart}</SecondText>
          <AboutLogoBig />
        </AboutSecondTextCntainer>
        <AboutBoxesContainer>
          <AboutBox>
            <AboutBoxTitle>
              <BoxIcon />
              {texts.about.boxes[0].title}
            </AboutBoxTitle>
            <AboutBoxText>{texts.about.boxes[0].text}</AboutBoxText>
          </AboutBox>
          <AboutBox>
            <AboutBoxTitle>
              <LikeIcon />
              {texts.about.boxes[1].title}
            </AboutBoxTitle>
            <AboutBoxText>{texts.about.boxes[1].text}</AboutBoxText>
          </AboutBox>
          <AboutBox>
            <AboutBoxTitle>
              <WorkerIcon />
              {texts.about.boxes[2].title}
            </AboutBoxTitle>
            <AboutBoxText>{texts.about.boxes[2].text}</AboutBoxText>
          </AboutBox>
          <AboutBox>
            <AboutBoxTitle>
              <MarkIcon />
              {texts.about.boxes[3].title}
            </AboutBoxTitle>
            <AboutBoxText>{texts.about.boxes[3].text}</AboutBoxText>
          </AboutBox>
        </AboutBoxesContainer>
        <AboutLogoSmall />
      </AboutSecond>
    </AboutContainer>
  );
};

export default About;
