import React, { FC, TouchEvent, useContext, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { LngContext } from "../../context/lng-context";

import {
  ArrowDrop,
  FBIcon,
  FullLogo,
  InstaIcon,
  LanguageChose,
  LinksContainer,
  LogoGreen,
  MenuIcon,
  MobileIconsContainer,
  MobileLngChose,
  MobileLngChoseContainer,
  MobileLogoContainer,
  MobileMenuContainer,
  MobileNavContainer,
  MobilePhoneContainer,
  NavContainer,
  PhoneContainer,
  TGIcon,
  TopMenuContainer,
} from "./top-menu.styles";

const TopMenu: FC<{
  navigation: {
    navToAbout: () => void | undefined;
    navToCatalog: () => void | undefined;
    navToContacts: () => void | undefined;
  };
}> = ({ navigation }) => {
  const [chosenLng, setChosenLng] = useState("Ру");
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [touchStart, setTouchStart] = useState<null | number>(null);
  const [touchEnd, setTouchEnd] = useState<null | number>(null);
  const [searchQuery, setSearchQuery] = useSearchParams();

  const texts = useContext(LngContext);

  const onTouchStart = (e: TouchEvent<HTMLDivElement>) => {
    setTouchEnd(null);
    setTouchStart(e.targetTouches[0].clientX);
  };

  const onTouchMove = (e: TouchEvent<HTMLDivElement>) =>
    setTouchEnd(e.targetTouches[0].clientX);

  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) return;
    const distance = touchStart - touchEnd;
    // const isLeftSwipe = distance > minSwipeDistance;
    // const isRightSwipe = distance < -minSwipeDistance;
    // if (isLeftSwipe || isRightSwipe)
    //   console.log("swipe", isLeftSwipe ? "left" : "right");
    if (distance >= 70 || distance <= -70) setIsMenuOpen(false);
  };

  useEffect(() => {
    switch (chosenLng) {
      case "Ру":
        setSearchQuery({ ...searchQuery, lng: "ru" });
        break;
      case "Уз":
        setSearchQuery({ ...searchQuery, lng: "uz" });
        break;
      default:
        setSearchQuery({ ...searchQuery, lng: "ru" });
        break;
    }
  }, [chosenLng]);

  const onChangeLng = () => {
    switch (chosenLng) {
      case "Ру":
        setChosenLng("Уз");
        break;
      case "Уз":
        setChosenLng("Ру");
        break;
      default:
        setChosenLng("Ру");
        break;
    }
  };

  const openInsta = () => {
    window.open(
      "https://www.instagram.com/amirtea_samarkand/profilecard/?igsh=Y2poc2xlcjUwOTNk",
      "_blank",
      "noopener,noreferrer"
    );
  };

  const openFB = () => {
    window.open(
      "https://www.facebook.com/share/19eaHTKstQ/?mibextid=LQQJ4d",
      "_blank",
      "noopener,noreferrer"
    );
  };

  return (
    <TopMenuContainer>
      <FullLogo />
      <LinksContainer>
        <InstaIcon onClick={openInsta} />
        {/* <TGIcon /> */}
        <FBIcon onClick={openFB} />
      </LinksContainer>
      <NavContainer>
        <span onClick={() => navigation.navToAbout()}>
          {texts.topMenu.about}
        </span>
        <span onClick={() => navigation.navToCatalog()}>
          {texts.topMenu.catalog}
        </span>
        <span onClick={() => navigation.navToContacts()}>
          {texts.topMenu.contact}
        </span>
      </NavContainer>
      <LanguageChose onClick={onChangeLng}>
        {chosenLng}
        <ArrowDrop />
      </LanguageChose>
      <PhoneContainer>+998 55 702 70 02</PhoneContainer>
      <MenuIcon onClick={() => setIsMenuOpen(!isMenuOpen)} />
      {isMenuOpen && (
        <MobileMenuContainer
          onTouchStart={onTouchStart}
          onTouchMove={onTouchMove}
          onTouchEnd={onTouchEnd}>
          <MobileLogoContainer>
            <LogoGreen />
            <MobileLngChoseContainer>
              <MobileLngChose
                onClick={() => (setChosenLng("Ру"), setIsMenuOpen(false))}
                $isSelected={chosenLng === "Ру"}>
                Ру
              </MobileLngChose>
              <MobileLngChose
                onClick={() => (setChosenLng("Уз"), setIsMenuOpen(false))}
                $isSelected={chosenLng === "Уз"}>
                Уз
              </MobileLngChose>
            </MobileLngChoseContainer>
            <MobilePhoneContainer>+998 55 702 70 02</MobilePhoneContainer>
          </MobileLogoContainer>
          <MobileNavContainer>
            <span
              onClick={() => (navigation.navToAbout(), setIsMenuOpen(false))}>
              {texts.topMenu.about}
            </span>
            <span
              onClick={() => (navigation.navToCatalog(), setIsMenuOpen(false))}>
              {texts.topMenu.catalog}
            </span>
            <span
              onClick={() => (
                navigation.navToContacts(), setIsMenuOpen(false)
              )}>
              {texts.topMenu.contact}
            </span>
          </MobileNavContainer>
          <MobileIconsContainer>
            <InstaIcon onClick={openInsta} />
            {/* <TGIcon /> */}
            <FBIcon onClick={openFB} />
          </MobileIconsContainer>
        </MobileMenuContainer>
      )}
    </TopMenuContainer>
  );
};

export default TopMenu;
