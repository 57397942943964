import styled from "styled-components";

import { ReactComponent as ArrowSvg } from "../../assets/svg/slide-arrow.svg";

export const PromotionContainer = styled.div`
  margin: 76px 5.4vw;
  /* display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
  overflow: hidden; */
  position: relative;

  @media screen and (max-width: 750px) {
    margin: 6px 0;
  }
`;

export const PromotionImgsContainer = styled.div`
  display: flex;
  gap: 95px;
  align-items: center;
  justify-content: flex-start;
  overflow-y: hidden;
  overflow-x: hidden;
  border-radius: 20px;
  position: relative;

  @media screen and (max-width: 750px) {
    gap: 40px;
    border-radius: 0;
  }
`;

export const PromotionImg = styled.img`
  width: 89.2vw;
  border-radius: 20px;
  background-size: cover;
  user-select: none;

  @media screen and (max-width: 750px) {
    width: 100vw;
    border-radius: 0;
  }
`;

export const DotContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  margin-top: 16px;

  @media screen and (max-width: 750px) {
    display: none;
  }
`;

interface IDotProps {
  $isSelected: boolean;
}

export const Dot = styled.span<IDotProps>`
  width: 16px;
  min-width: 16px;
  height: 16px;
  min-height: 16px;
  background: ${({ $isSelected }) =>
    $isSelected ? "rgba(47, 174, 27, 1)" : "rgba(217, 217, 217, 1);"};
  border-radius: 50%;
`;

export const ArrowContainer = styled.div`
  z-index: 300;
  width: 100%;
  box-sizing: border-box;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, 50%);

  @media screen and (max-width: 750px) {
    display: none;
  }
`;

export const ArrowLeft = styled(ArrowSvg)`
  width: 52px;
  height: 52px;
`;

export const ArrowRight = styled(ArrowLeft)`
  transform: rotate(180deg);
`;
