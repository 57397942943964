import React, { useRef, useState, TouchEvent, useEffect } from "react";

import promo1 from "../../assets/img/promo-1.jpg";
import promo2 from "../../assets/img/promo-2.jpg";
import promo3 from "../../assets/img/promo-3.jpg";
import promo4 from "../../assets/img/promo-4.jpg";
import promo5 from "../../assets/img/promo-5.jpg";
import promo6 from "../../assets/img/promo-6.jpg";
import promo7 from "../../assets/img/promo-7.jpg";

import {
  ArrowContainer,
  ArrowLeft,
  ArrowRight,
  Dot,
  DotContainer,
  PromotionContainer,
  PromotionImg,
  PromotionImgsContainer,
} from "./promotion.styles";

const promos = [promo1, promo2, promo3, promo4, promo5, promo6, promo7];

const Promotion = () => {
  const [currentPromo, setCurrentPromo] = useState(0);
  const promoContainerRef = useRef<null | HTMLDivElement>(null);
  const [touchStart, setTouchStart] = useState<null | number>(null);
  const [touchEnd, setTouchEnd] = useState<null | number>(null);
  const minSwipeDistance = 70;

  // setInterval(() => promoScrollForward(), 10000);

  const onTouchStart = (e: TouchEvent<HTMLDivElement>) => {
    setTouchEnd(null);
    setTouchStart(e.targetTouches[0].clientX);
  };

  const onTouchMove = (e: TouchEvent<HTMLDivElement>) =>
    setTouchEnd(e.targetTouches[0].clientX);

  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) return;
    const distance = touchStart - touchEnd;
    const isLeftSwipe = distance > minSwipeDistance;
    const isRightSwipe = distance < -minSwipeDistance;
    if (isLeftSwipe) promoScrollForward();
    if (isRightSwipe) promoScrollBack();
  };

  const promoScrollForward = () => {
    const screenWidth = window.outerWidth;
    const nextPromo = currentPromo === promos.length - 1 ? 0 : currentPromo + 1;
    if (screenWidth > 750)
      promoContainerRef.current?.scrollTo({
        left: nextPromo * (screenWidth * 0.892) + 95 * nextPromo,
        behavior: "smooth",
      });
    else
      promoContainerRef.current?.scrollTo({
        left: nextPromo * screenWidth + 40 * nextPromo,
        behavior: "smooth",
      });
    setCurrentPromo(nextPromo);
  };

  const promoScrollBack = () => {
    const screenWidth = window.outerWidth;
    const nextPromo = currentPromo === 0 ? promos.length - 1 : currentPromo - 1;
    if (screenWidth > 750)
      promoContainerRef.current?.scrollTo({
        left: nextPromo * (screenWidth * 0.892) + 95 * nextPromo,
        behavior: "smooth",
      });
    else
      promoContainerRef.current?.scrollTo({
        left: nextPromo * screenWidth + 40 * nextPromo,
        behavior: "smooth",
      });
    setCurrentPromo(nextPromo);
  };

  return (
    <PromotionContainer>
      <ArrowContainer>
        <ArrowLeft onClick={promoScrollBack} />
        <ArrowRight onClick={promoScrollForward} />
      </ArrowContainer>
      <PromotionImgsContainer
        ref={promoContainerRef}
        onTouchStart={onTouchStart}
        onTouchMove={onTouchMove}
        onTouchEnd={onTouchEnd}>
        {promos.map((img, i) => (
          <PromotionImg
            src={img}
            key={i}
          />
        ))}
      </PromotionImgsContainer>
      <DotContainer>
        {promos.map((_, i) => (
          <Dot
            $isSelected={i === currentPromo}
            key={i}
          />
        ))}
      </DotContainer>
    </PromotionContainer>
  );
};

export default Promotion;
