import React, { Suspense, useEffect, useRef, useState } from "react";
import { Route, Routes, useSearchParams } from "react-router-dom";

import textsJson from "../assets/texts.json";
import { LngContext } from "../context/lng-context";

import Header from "../components/header/header.component";
import TopMenu from "../components/top-menu/top-menu.component";
import About from "../components/about/about.component";
import Promotion from "../components/promotion/promotion.component";
import Contact from "../components/contact/contact.component";
import Map from "../components/map/map.component";
import Fotter from "../components/footer/footer.component";

function AppLoyout() {
  const [texts, setTexts] = useState(textsJson.ru);
  const [searchParams] = useSearchParams();
  const aboutRef = useRef<HTMLDivElement | null>(null);
  const catalogRef = useRef<HTMLDivElement | null>(null);
  const contactsRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    switch (searchParams.get("lng")) {
      case "ru":
        setTexts(textsJson.ru);
        break;
      case "uz":
        setTexts(textsJson.uz);
        break;
      default:
        setTexts(textsJson.ru);
        break;
    }
  }, [searchParams]);

  const navigation = {
    navToAbout: () => aboutRef.current?.scrollIntoView({ behavior: "smooth" }),
    navToCatalog: () =>
      catalogRef.current?.scrollIntoView({ behavior: "smooth" }),
    navToContacts: () =>
      contactsRef.current?.scrollIntoView({ behavior: "smooth" }),
  };

  return (
    <>
      <LngContext.Provider value={texts}>
        <TopMenu navigation={navigation} />
        <Header navToContact={navigation.navToContacts} />
        <span ref={aboutRef} />
        <About />
        <span ref={catalogRef} />
        <Promotion />
        <span ref={contactsRef} />
        <Contact />
        <Map navToContact={navigation.navToContacts} />
        <Fotter navigation={navigation} />
      </LngContext.Provider>
    </>
  );
}

export default AppLoyout;
